import React from "react";
import { DisplayPrefs } from "./index";
import {
  MobileConfigRowDisplayData,
  MultipleIndicators,
  Network,
  TypeOfConnection,
} from "../../../../../shared/types/MobileProductConfig";
import {
  Checkbox,
  Chip,
  Button,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatCurrency } from "../../../../../shared/utils/formatCurrency";
import { ExpanderIcon } from "../../../../../shared/components/ExpanderIcon";
import { ConfigStatusExpanded } from "../../../../../shared/components/ConfigStatusExpanded";
import { ConnectionType } from "../../../../../shared/components/MobileProduct/ConnectionType";
import { MultiIndicator } from "./MultiIndicator";
import { ProductTitle } from "../../../../../shared/components/MobileProduct/ProductTitle";
import { Delete, Edit } from "@mui/icons-material";
import { CommissionRadio } from "../../../../../shared/components/CommissionRadio";
import { StatusChip } from "@akj-dev/shared-components";

// or all in one?
export interface MobileConfigTableRowProps {
  displayPrefs: DisplayPrefs;
  root: boolean;
  data: MobileConfigRowDisplayData;
  multiples?: MultipleIndicators;
  productName?: string;
  network?: Network;
  count?: number;
  expanded?: boolean;
  onExpand?: () => void;
  onEdit?: (e: React.MouseEvent) => void;
  onDelete?: (e: React.MouseEvent) => void;
  selected?: boolean;
  onSelect?: (e: React.MouseEvent) => void;
  onCommissionSelect?: (e: any) => void;
  isCheckBoxHidden: boolean;
  isOneMonthContract: boolean;
  onRetry?: () => void;
}

export const MobileConfigTableRow = (props: MobileConfigTableRowProps) => {
  const classes = useStyles(props);
  const {
    root,
    displayPrefs,
    data,
    multiples = {},
    productName,
    network,
    expanded,
    count,
    onExpand,
    onEdit,
    onDelete,
    selected,
    onSelect,
    onCommissionSelect,
    isCheckBoxHidden,
    isOneMonthContract,
    onRetry,
  } = props;

  if (data.status === "error")
    return (
      <TableRow>
        <TableCell colSpan={8}>
          <StatusChip
            type="error"
            title="Error fetching product data."
            retry={onRetry}
          />
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow className={classes.root} onClick={onExpand}>
      <TableCell align="center">
        {onExpand && (
          <ExpanderIcon expanded={expanded} data-cy="expandConfigGroup" />
        )}
      </TableCell>
      <TableCell align="center">
        {root && count && count > 1 ? (
          <Chip
            size="small"
            color="secondary"
            variant="filled"
            label={count}
            className={classes.count}
          />
        ) : isCheckBoxHidden ? (
          <>{/*empty*/}</>
        ) : (
          <Checkbox
            color="secondary"
            checked={selected}
            onClick={onSelect}
            data-cy="selectConfig"
          />
        )}
      </TableCell>
      <TableCell align="center">
        <MultiIndicator multiple={multiples.cli} value={data.cli} />
      </TableCell>
      <TableCell align="left">
        {network && (
          <ProductTitle network={network}>{productName}</ProductTitle>
        )}
      </TableCell>
      <TableCell align="center">
        <MultiIndicator
          multiple={multiples.price}
          value={formatCurrency(data.price)}
        />
      </TableCell>
      {displayPrefs.showMonthlyAirtimeCredit && (
        <TableCell align="center">
          <MultiIndicator
            multiple={
              multiples.monthlyAirtimeCredit ||
              multiples.monthlyAirtimeCreditDuration
            }
            value={
              data.monthlyAirtimeCredit
                ? `${formatCurrency(parseFloat(data.monthlyAirtimeCredit))}${
                    data.monthlyAirtimeCreditDuration > 0
                      ? " x " + data.monthlyAirtimeCreditDuration + "m"
                      : ""
                  }`
                : "£0.00"
            }
          />
        </TableCell>
      )}
      {displayPrefs.showOneOffAirtimeCredit && (
        <TableCell align="center">
          <MultiIndicator
            multiple={multiples.oneOffAirtimeCredit}
            value={formatCurrency(parseFloat(data.oneOffAirtimeCredit))}
          />
        </TableCell>
      )}
      {displayPrefs.showVFDiscounts && (
        <TableCell align="center">
          <MultiIndicator
            multiple={multiples.sharedDiscount}
            value={formatCurrency(data.sharedDiscount)}
          />
        </TableCell>
      )}
      {displayPrefs.showVFDiscounts && (
        <TableCell align="center">
          <MultiIndicator
            multiple={multiples.commissionSacrifice}
            value={formatCurrency(data.commissionSacrifice)}
          />
        </TableCell>
      )}
      <TableCell align="center">
        <MultiIndicator
          multiple={multiples.connectionType}
          value={<ConnectionType type={data.connectionType} />}
        />
      </TableCell>
      {displayPrefs.showCommissionTypeSelect && (
        <TableCell align="center" className={classes.commissionCell}>
          <MultiIndicator
            multiple={multiples.commissionType}
            value={
              <CommissionRadio
                disabled={
                  isOneMonthContract ||
                  data.connectionType ===
                    TypeOfConnection.RESIGN_WITHOUT_CHANGES
                }
                commissionType={data.commissionType}
                onCommissionSelect={onCommissionSelect}
              />
            }
          />
        </TableCell>
      )}
      <TableCell align="center">
        <MultiIndicator
          multiple={multiples.valid}
          value={<ConfigStatusExpanded isValid={data.valid} />}
        />
      </TableCell>
      <TableCell align="right">
        {displayPrefs.showActions && (
          <>
            {data.connectionType !==
              TypeOfConnection.RESIGN_WITHOUT_CHANGES && (
              <Button
                onClick={onEdit}
                data-cy="editMobileConfig"
                variant="contained"
                startIcon={<Edit />}
              >
                Edit
              </Button>
            )}
            <Button
              className={classes.deleteButton}
              onClick={onDelete}
              variant="contained"
              color="primary"
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles<Theme, MobileConfigTableRowProps>((theme) => ({
  root: {
    backgroundColor: (props) => (props.root ? "white" : theme.palette.grey[50]),
    borderLeft: (props) =>
      props.root ? "inherit" : `6px solid ${theme.palette.grey[300]}`,
    "& .MuiTableCell-root": {
      padding: 4,
    },
  },
  count: {
    marginLeft: 0,
  },
  deleteButton: {
    marginLeft: 5,
    background: "#cc3324",
    "&:hover": {
      backgroundColor: "#bb2e1e",
    },
  },
  radio: {
    marginRight: 0,
    marginLeft: 0,
  },
  commissionCell: { padding: 0, maxWidth: "100%" },
}));
