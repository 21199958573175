import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import AddBoxIcon from "@mui/icons-material/AddBox";

import { Section } from "../../../../../../shared/components/Section";
import { setAdditionalBundle } from "../../../../../../store/mobile/actions";
import { getProductDataForConfig } from "../../../../../../store/mobile/selectors/productConfig";
import { AdditionalBundles } from "../../../../components/Mobile/AdditionalBundles";

export const AdditionalBundle = ({ targetConfigs }: any) => {
  const dispatch = useDispatch();
  const [bundleId, setBundleId] = useState("0");

  const bundles = useSelector((state) =>
    _.get(
      getProductDataForConfig(state, targetConfigs[0]),
      "mobile.dynamic_properties.additional_bundle"
    )
  );

  if (!bundles) return null;

  return (
    <Section title={"Additional Bundles"} avatar={<AddBoxIcon />}>
      <AdditionalBundles
        propertyName="additional_bundle"
        bundles={bundles}
        selectedBundleId={bundleId}
        onChangeBundle={(event) => {
          setBundleId(event.target.value);
          dispatch(setAdditionalBundle(event.target.value, targetConfigs[0]));
        }}
      />
    </Section>
  );
};
