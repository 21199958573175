import React, { useState } from "react";
import { Button, Link, Menu, MenuItem, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export interface OptionsMenuProps {
  showDcOption: boolean;
  showDeleteOption: boolean;
  accountId: string;
  orderStatusOpen: boolean;
  quoteDisabled: boolean;
  handleOpenDeleteDraft: () => void;
  handleGenerateQuote: () => void;
  saveDraft: (newName?: string) => void;
  handleOrderStatus: () => void;
  handleOpenBulkOrder: () => void;
}

export const OptionsMenu = ({
  showDcOption,
  showDeleteOption,
  accountId,
  orderStatusOpen,
  quoteDisabled,
  handleOpenDeleteDraft,
  saveDraft,
  handleGenerateQuote,
  handleOrderStatus,
  handleOpenBulkOrder,
}: OptionsMenuProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const onSaveDraft = () => {
    saveDraft();
    handleCloseMenu();
  };

  const onHandleOpenBulkOrder = () => {
    handleOpenBulkOrder();
    handleCloseMenu();
  };

  const onHandleGenerateQuote = () => {
    handleGenerateQuote();
    handleCloseMenu();
  };

  const onHandleOpenDeleteDraft = () => {
    handleOpenDeleteDraft();
    handleCloseMenu();
  };

  // const onHandleRenameDraft = () => {
  //   handleRenameDraft();
  //   handleCloseMenu();
  // }

  return (
    <>
      <Button
        data-cy="ToolbarOptions"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleOpenMenu}
      >
        <div className={classes.text}>Options</div>
        <div className={classes.icon}>
          {!!anchorEl ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        {showDcOption && (
          <Link
            href="https://giacom.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            color="inherit"
            underline="none"
          >
            <MenuItem
              data-cy="ToolbarRaiseSupportCase"
              className={classes.menuItem}
            >
              Raise Support Case
            </MenuItem>
          </Link>
        )}
        {showDcOption && (
          <MenuItem
            data-cy="ToolbarBulkUpload"
            className={classes.menuItem}
            onClick={onHandleOpenBulkOrder}
          >
            Bulk Upload
          </MenuItem>
        )}
        {showDcOption && !!accountId && (
          <MenuItem
            data-cy="ToolbarOrderStatus"
            className={classes.menuItem}
            onClick={handleOrderStatus}
          >
            {`${orderStatusOpen ? "Hide" : "Show"} Order Status`}
          </MenuItem>
        )}
        <MenuItem
          data-cy="ToolbarSaveDraft"
          className={classes.menuItem}
          onClick={onSaveDraft}
        >
          Save Draft
        </MenuItem>
        {/*<MenuItem className={classes.menuItem} onClick={handleRenameDraft}>*/}
        {/*  Rename Draft*/}
        {/*</MenuItem>*/}
        <MenuItem
          data-cy="ToolbarSendQuote"
          className={classes.menuItem}
          disabled={quoteDisabled}
          onClick={quoteDisabled ? undefined : onHandleGenerateQuote}
        >
          Send Quote
        </MenuItem>
        {showDeleteOption && (
          <MenuItem
            data-cy="ToolbarDeleteDraft"
            className={classes.menuItem}
            onClick={onHandleOpenDeleteDraft}
          >
            <span className={classes.dangerText}>Delete Draft</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  text: {
    fontWeight: "bold",
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    borderRight: `1px solid ${theme.palette.primary.dark}`,
  },
  dangerText: {
    color: theme.palette.error.main,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.spacing(0.5)}`,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
