import * as MobileAPI from "../../../api/v1/mobile";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountSettings } from "../../account/selectors";

let validationMessages: any[] = [];

/**
 * Set fields for a product
 *
 * @param productId {Int}
 * @param fields {Object}
 * @param index {Int=}
 *
 * @returns {Object}
 */

export const setFields = createAction(
  "hardwareConfigurations/setFields",
  (productId: number | string, fields: any, index?: number) => ({
    payload: { productId, fields, index },
  })
);

/**
 * Set quantity for a product
 *
 * @param product {Object}
 * @param quantity {Int}
 * @param daisyFreshQuantity {Int}
 *
 * @returns {Object}
 */
export const setQuantityAndDaisyFreshHardwareTotal = createAsyncThunk(
  "hardwareConfigurations/setQuantityAndDaisyFreshHardwareTotal",
  async (
    { product, quantity, daisyFreshQuantity }: any,
    { getState, dispatch }
  ) => {
    dispatch(setQuantity({ product, quantity, daisyFreshQuantity }));
    dispatch(setDaisyFreshHardwareTotal());
  }
);

export const setQuantity = createAsyncThunk(
  "hardwareConfigurations/setQuantity",
  async ({ product, quantity, daisyFreshQuantity }: any, { getState }) => {
    const state: any = getState();
    return {
      product,
      quantity: parseInt(quantity || 0),
      daisyFreshQuantity: parseInt(daisyFreshQuantity || 0),
      commissionType:
        getAccountSettings(state).as_commission_type_selection_for_hardware ===
        "1"
          ? "Upfront"
          : null,
    };
  }
);

export const setDaisyFreshHardwareTotal = createAction(
  "mobile/setDaisyFreshHardwareTotal"
);

/**
 * Clear hardware configurations
 *
 * @returns {Object}
 */
export const clearConfig = createAction("hardwareConfigurations/clearConfig");

/**
 * Expand/collapse a product
 *
 * @param productId {Int}
 *
 * @returns {Object}
 */
export const toggleExpand = createAction<number>(
  "hardwareConfigurations/toggleExpand"
);

/**
 * Validation errors
 *
 * @param configurations {Object}
 *
 * @returns {String}
 */
export function validationErrors(configurations: any) {
  return validationMessages.join("\n");
}

export const getStockLevel = createAsyncThunk(
  "hardwareConfigurations/getStockLevel",
  async (productId: number) => {
    const response = await MobileAPI.getStockLevel(productId);
    return { productId, response };
  }
);
