import React, { useCallback, useMemo, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ChooseHardwareTableRow } from "../components/Row";
import { MAX_QTY } from "../constants";
import {
  getStockLevel,
  setQuantity,
} from "../../../../store/hardware/configurations/actions";
import { getDaisyFreshAvailableForContractLength } from "../../../../store/mobile/selectors/daisyFresh";
import { HardwareTableData } from "../types";

interface ChooseHardwareTableRenderRowProps {
  index: number;
  style: CSSProperties;
  data: HardwareTableData;
}

export const ChooseHardwareTableRenderRow = ({
  index,
  style,
  data: {
    hasConnections,
    configsCount,
    products,
    hardwareConfigurations,
    daisyFreshHardwareTotal,
    daisyFreshAllowedAmount,
  },
}: ChooseHardwareTableRenderRowProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const daisyFreshAvailableForContractLength = useSelector(
    getDaisyFreshAvailableForContractLength
  );

  const product = products[index];
  const configuration = hardwareConfigurations[product.id];

  const memoizedProduct = useMemo(
    () => ({
      id: product.id,
      name: product.name,
      price: {
        oneOffWithConnection: product.price.oneOffWithConnection,
        oneOff: product.price.oneOff,
        daisyFresh: product.price.daisyFresh,
      },
    }),
    [
      product.id,
      product.name,
      product.price.daisyFresh,
      product.price.oneOff,
      product.price.oneOffWithConnection,
    ]
  );

  const memoizedConfiguration = useMemo(
    () => ({
      daisyFreshQuantity: configuration?.daisyFreshQuantity,
      stockLevel: configuration?.stockLevel,
      quantity: configuration?.quantity,
    }),
    [
      configuration?.daisyFreshQuantity,
      configuration?.quantity,
      configuration?.stockLevel,
    ]
  );

  const daisyFreshQuantity = configuration?.daisyFreshQuantity || 0;
  const quantity = configuration?.quantity;
  const handleDaisyFreshSetQuantity = useCallback(
    (value) => {
      dispatch(
        setQuantity({
          product: memoizedProduct,
          quantity,
          daisyFreshQuantity: value,
        })
      );
    },
    [memoizedProduct, quantity, dispatch]
  );

  const handleSetQuantity = useCallback(
    (value) => {
      dispatch(
        setQuantity({
          product: memoizedProduct,
          quantity: value,
          daisyFreshQuantity,
        })
      );
    },
    [memoizedProduct, daisyFreshQuantity, dispatch]
  );

  const handleStockLevel = useCallback(
    (productId: number) => {
      dispatch(getStockLevel(productId));
    },
    [dispatch]
  );

  const oneOffPrice =
    product?.price?.[hasConnections ? "oneOffWithConnection" : "oneOff"];

  const daisyFreshAllowedAmountRemaining =
    daisyFreshAllowedAmount - daisyFreshHardwareTotal;

  const daisyFreshAllowedQuantity = Math.floor(
    daisyFreshAllowedAmountRemaining / parseFloat(oneOffPrice)
  );

  const daisyFreshLimitExceeded =
    daisyFreshAllowedQuantity < 1 ||
    parseFloat(oneOffPrice) > daisyFreshAllowedAmountRemaining;

  const isDaisyFreshAvailable =
    hasConnections &&
    daisyFreshAvailableForContractLength &&
    (!daisyFreshLimitExceeded ||
      (daisyFreshQuantity > 0 && daisyFreshLimitExceeded));

  const quantityThreshold =
    daisyFreshAllowedQuantity < 10
      ? daisyFreshQuantity + daisyFreshAllowedQuantity
      : 10;

  return (
    <Box data-cy={product.name} className={classes.root} style={style}>
      <ChooseHardwareTableRow
        product={memoizedProduct}
        configuration={memoizedConfiguration}
        handleSetQuantity={handleSetQuantity}
        handleDaisyFreshSetQuantity={handleDaisyFreshSetQuantity}
        getStockLevel={handleStockLevel}
        hasReachedMaxConfigCount={MAX_QTY < configsCount}
        isDaisyFreshAvailable={isDaisyFreshAvailable}
        quantityTreshold={quantityThreshold}
        daisyFreshQuantity={daisyFreshQuantity}
        oneOffPrice={oneOffPrice}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));
